<template>
  <div>
    <v-container>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 py-0">
          <v-breadcrumbs class="pb-0" :items="breadcrums">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="toLink(item.href)"
                :disabled="item.disabled"
                >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 col-6 div-img-pro-detail">
          <v-img width="100%" height="300px" :src="getImg()" contain></v-img>
          <!-- <v-carousel>
            <v-carousel-item
              :src="require('../assets/img/home/slider4.jpg')"
            >
            </v-carousel-item>
            <v-carousel-item
              :src="require('../assets/img/home/slider2.jpg')"
            >
            </v-carousel-item>
            <v-carousel-item
              :src="require('../assets/img/home/slider3.jpg')"
            >
            </v-carousel-item>
            <v-carousel-item
              :src="require('../assets/img/home/slider1.jpg')"
            >
            </v-carousel-item>

          </v-carousel> -->
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12 pr-0 col-6 pb-0">
          <div class="detail-pro">
            <p class="display-1 mb-0 name-pro" style="color: #044D7F;">{{modelProduct['name']}}</p>
            <p class="mb-1">
              - Đường kính trong: <span style="color: #0673C0;">{{modelProduct['dkt']}}</span>
            </p>
            <p class="mb-1">
              - Đường kính ngoài: <span style="color: #0673C0;">{{modelProduct['dkn']}}</span>
            </p>
            <p class="mb-1">
              - Độ dày vòng bi: <span style="color: #0673C0;">{{modelProduct['doDay']}}</span>
            </p>
            <p class="mb-1">
              - Trọng lượng: <span style="color: #0673C0;">{{modelProduct['trongLuong']}}</span>
            </p>
            <p class="mb-1">
              - Chủng loại: <span style="color: #0673C0;">{{modelProduct['chungLoai']}}</span>
            </p>
            <p class="mb-1">
              - Tình trạng: <span style="color: #0673C0;">{{modelProduct['tinhTrang']}}</span>
            </p>
            <p class="mb-1">
              - Giá : <span style="color: #0673C0;">{{modelProduct['gia']}}</span>
            </p>

          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-12 pr-0 col-5">

        </div>
        <div class="col-md-5 col-sm-5 col-xs-12 pr-0 col-7">
          <v-btn
              tile
              style="background: #044D7F; color: #fff;"
              >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Tìm kiếm nâng cao
            </v-btn>
        </div>

        <div class="col-md-3 col-sm-3 col-xs-12 pl-0">
          <div class="lien-he" style="border: 1px solid#ccc; width: 100%; padding: 12px; margin-top: 50px;">
            <div class="name-product-detail">Liên hệ ngay </div>

            <div>
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; color: #df900d;">mdi-phone-classic</v-icon>
                <span style="font-family: 'Bebas Neue'; font-size: 16px; color:#e30018;">0916 045 428</span>
              </a>
            </div>
            <div>
              <v-icon style="font-size: 17px; color: #df900d;">mdi-email</v-icon>

              <a href="mailto:vongbicongnghiep.gbp@gmail.com" style="color: #e30018; font-size: 13px;">vongbicongnghiep.gbp@gmail.com</a>
            </div>

          </div>
        </div>
      </div>


      <v-card-text
            class="pa-0 pt-4"
            tile
            outlined
          >
            <p class="pt-3" style="font-weight: bold; font-size: 19px;">Sản phẩm liên quan</p>
            <v-divider></v-divider>
            <div class="row text-center">
              <div class="col-md-3 col-sm-6 col-xs-12 col-6" :key="products[index]['id']" v-for="index in indexMaxSpLienQuan">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    class="mx-auto"
                    :class="{ 'on-hover': hover }"
                    color="grey lighten-4"
                    max-width="600"
                  >
                    <v-img
                      style="cursor: pointer;"
                      @click="toDetailProduct(products[index], index)"
                      class="white--text align-end img-product"
                      :aspect-ratio="16/9"
                      height="200px"
                      :src="getImgRandom(products[index]['img'], products[index], index)"
                    >
                    </v-img>
                    <v-card-text class="text--primary">
                      <div><a href="javascript:;" @click="toDetailProduct(products[index], index)" style="text-decoration: none; color: blue; font-weight: bold;">{{products[index]['name']}}</a></div>
                      <div>{{products[index]['chungLoai']}}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 text-center" v-if="!indexMaxSpLienQuan">
                <span>Không tìm thấy dữ liệu</span>
              </div>
            </div>
          </v-card-text>
    </v-container>
    <v-card  class="hotline" >
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-12 col-sm-12 text-center">
            <div class="container">
              <span class="dot" style="margin-left: 5px; margin-top: -4px;">
                <span class="ping"></span>
              </span>
              <span style="font-weight:300; color: #fff;">Hotline hỗ trợ:</span>
              <!-- <i class="fas fa-phone-square" style="color:#38c500;padding-left: 18px"></i> -->
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span class="phone-number-product" style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">024 668 42879</span>
              </a>
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span class="phone-number-product" style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">0916 045 428</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
    import productItems from '@/plugins/sanPham.json'
    export default {
        data: () => ({
            rating:4.5,
            modelProduct: {
            },
            indexMaxSpLienQuan: 0,
            products: [],
            breadcrums: [
                {
                    text: 'Trang chủ',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Sản phẩm',
                    disabled: false,
                    href: 'shop',
                }
            ],
            item: 5
        }),
        created () {
          let vm = this
          vm.$nextTick(function () {
            vm.getDetail()
            window.scrollTo(0, 0);
          })
        },
        methods: {
          toLink (link) {
            let vm = this
            if (link) {
              vm.$router.push({
                path: link
              })
            }
          },
          getDetail () {
            let vm = this
            // let query = vm.$router.history.current.query
            // if (!query['name']) {
            //   return
            // }
            // let item = productItems.filter(item => {
            //   return item['name'] === query['name']
            // })
            // vm.modelProduct = item || {}
            let str = window.localStorage.getItem("product")
            if (str) {
              vm.modelProduct = JSON.parse(str) || {}
              vm.getRandomItem()
              let index = vm.breadcrums.findIndex(item => {
                return item['disabled']
              })
              if (index === -1) {
                vm.breadcrums.push({
                    text: vm.modelProduct['name'] || '',
                    disabled: true
                })
              }
            }
            console.log('JSON.parse(str)============', JSON.parse(str))
          },
          getRandomItem () {
            let vm = this
            let length = productItems['length']
            let tmp = []
            if (!length) {
              vm.products = []
              return
            }
            tmp = productItems.filter(item => {
              return item['chungLoaiCode'] === vm.modelProduct['chungLoaiCode'] && item['name'] !== vm.modelProduct['name']
            })
            if (tmp['length'] >= 8) {
              vm.indexMaxSpLienQuan = 8
            } else {
              vm.indexMaxSpLienQuan = tmp['length'] - 1
            }
            console.log('vm.products============', vm.products)
            vm.products = tmp || []
          },
          getImg () {
            let vm = this
            if (!vm.modelProduct['img'] || !vm.modelProduct['imgCode'] || !vm.modelProduct['imgType']) {
              return ''
            }
            return require('../assets/img/shop/' + vm.modelProduct['img'] + '_' + (vm.modelProduct['imgCode'] || 1) + '.' + vm.modelProduct['imgType'])
          },
          toDetailProduct (item, index) {
            let vm = this
            window.localStorage.setItem("product", JSON.stringify(item) || '');
            vm.$router.push({
              path: '/product',
              query: {
                name: item['name']
              }
            })
            vm.getDetail()
            window.scrollTo(0, 0);
          },
          getImgRandom (img, item, index) {
            let vm = this
            if (item['imgCode']) {
              return require('../assets/img/shop/' + img + '_' + item['imgCode'] + '.' + item['imgType'])
            }
            let bacDanCauMax = 4
            let bacDanCauDoChanMax = 5
            let bacDanCauTuLuaMax = 4
            let bacDanChanTrucMax = 4
            let bacDanChanTrucTangChong = 5
            let bacDanConMax = 4
            let bacDanDua = 4
            let bacDanKimMax = 5
            let bacDanTangChongMax = 4
            let bacDanTiepXuc4DiemMax = 4
            let bacDanTiepXucGoc2Day = 4
            let tmpRd = 0
            if (img === 'bac_dan_cau') {
              tmpRd = Math.floor(Math.random() * bacDanCauMax) + 1
            } else if (img === 'bac_dan_cau_do_chan') {
              tmpRd = Math.floor(Math.random() * bacDanCauDoChanMax) + 1
            } else if (img === 'bac_dan_cau_tu_lua') {
              tmpRd = Math.floor(Math.random() * bacDanCauTuLuaMax) + 1
            } else if (img === 'bac_dan_chan_truc') {
              tmpRd = Math.floor(Math.random() * bacDanChanTrucMax) + 1
            } else if (img === 'bac_dan_chan_truc_tang_chong') {
              tmpRd = Math.floor(Math.random() * bacDanChanTrucTangChong) + 1
            } else if (img === 'bac_dan_con') {
              tmpRd = Math.floor(Math.random() * bacDanConMax) + 1
            } else if (img === 'bac_dan_dua') {
              tmpRd = Math.floor(Math.random() * bacDanDua) + 1
            } else if (img === 'bac_dan_kim') {
              tmpRd = Math.floor(Math.random() * bacDanKimMax) + 1
            } else if (img === 'bac_dan_tang_chong') {
              tmpRd = Math.floor(Math.random() * bacDanTangChongMax) + 1
            } else if (img === 'bac_dan_tiep_xuc_4_diem') {
              tmpRd = Math.floor(Math.random() * bacDanTiepXuc4DiemMax) + 1
            } else if (img === 'bac_dan_tiep_xuc_goc_2_day') {
              tmpRd = Math.floor(Math.random() * bacDanTiepXucGoc2Day) + 1
            }
            item['imgCode'] = tmpRd
            return require('../assets/img/shop/' + img + '_' + tmpRd + '.' + item['imgType'])
          }
        }
    }
</script>

<style>
  .name-product-detail {
    color: #044d7f;
    font-size: 24px;
    font-weight: normal;
  }

  .nav__icon {
    display: none;
  }
  .space-nav {
    display: none;
  }
  .detail-pro {
    padding-left: 24px;
  }

  @media only screen and (max-width: 600px) {
    /*div.hang-san-xuat {
      display: none;
    }*/

    .img-product {
      height: 120px !important;
    }

    .whyBox {
      padding: 15px 0px !important;
    }

    .whyBox ._item {
      padding-top: 0;
      padding-left: 22px;
      padding-right: 20px;
    }

    .whyBox .box-heading {
      font-size: 20px;
    }
    .whyBox .box-website {
      font-size: 19px !important;
    }

    div.whyBox > .col-12.col-md-12.col-sm-12.col-xs-12.text-center {
      padding-left: 10px;
      padding-right: 10px;
    }

    div.hang-san-xuat {
      height: 250px;
      overflow: auto;
      padding-top: 0;
    }

    div.hang-san-xuat .v-responsive.v-image.white--text.align-end {
      height: 70px !important;
    }
    div.v-toolbar__title {
      width: 90px !important;
    }
    .v-responsive.v-image.v-carousel__item {
      height: 150px !important;
    }
    .v-window.v-item-group.theme--dark.v-window--show-arrows-on-hover.v-carousel.v-carousel--hide-delimiter-background {
      height: 150px !important;
    }
    .img-type-home {
      height: 120px !important;
    }
    .__nav {
      display: none !important;
    }
    .img-footer-logo {
      height: 80px !important;
    }
    .space-nav {
      height: 116%;
      width: 1px;
      background: #ccc;
      padding-left: 1px;
      margin-left: 5px;
      margin-right: 12px;
    }
    .nav__icon {
      display: block;
    }
    .space-nav {
      display: block;
    }
    .detail-pro {
      padding-left: 10px !important;
    }
    .name-pro {
      font-size: 19px !important;
    }
    .lien-he {
      padding-left: 21px !important;
      margin-left: 6px;
      margin-top: 5px !important;
    }
    .div-img-pro-detail {
      padding-right: 0;
    }
    .spacer {
      display: none;
    }
    .v-app-bar__nav-icon {
      padding-right: 5px !important;
    }
    .v-application .display-1 {
      font-size: 0.9rem!important;
      line-height: 1.5rem;
      letter-spacing: .0073529412em!important;
    }
    .phone-number-product {
      font-size: 15px !important;
    }
  }
</style>
