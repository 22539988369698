<template>
  <div>
    <v-container>
      <div class="row">
        <div
         class="col-md-3 col-sm-3 col-xs-12"
        >
          <v-card outlined>
            <v-card-title class="py-2" style="color: #fff; background: #044D7F; font-size: 17px;">DANH MỤC SẢN PHẨM</v-card-title>
            <v-list class="pt-0 list-category">
              <template v-for="(item, index) in danhMucItems">
                <v-divider :key="item.text + index"></v-divider>
                <v-list-item
                  class="item_list"
                  :key="item.text + index"
                  :class="{'active': indexSelectLoai === index}"
                  @click="toDetail(item, index)"
                  >
                  <v-list-item-content style="cursor: pointer;">
                    <v-list-item-title v-html="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

            <v-card-title class="py-2" style="color: #fff; background: #044D7F; font-size: 17px;">THƯƠNG HIỆU</v-card-title>
            <v-list class="pt-0 list-company">
              <template v-for="(item, index) in hangItems">
                <v-divider :key="item.text + index"></v-divider>
                <v-list-item
                  class="item_list"
                  :key="item.text + index"
                  :class="{'active': indexSelectHang === index}"
                  @click="toDetailHang(item, index)"
                  >
                  <v-list-item-avatar>
                    <v-img :src="require('../assets/img/home/' + item['img'] + '.jpg')" contain></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content style="cursor: pointer;">
                    <v-list-item-title v-html="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <!-- <template>
              <v-treeview :items="items" :open="[1]" :active="[5]" :selected-color="'#fff'" activatable open-on-click dense></v-treeview>
            </template> -->
            <!-- <v-divider></v-divider>
            <v-card-title>Price</v-card-title>
            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              :height="10"
              class="align-center"
              dense
            ></v-range-slider>
            <v-row class="pa-2" dense>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="range[0]"
                  label="Min"
                  outlined
                  dense
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <p class="pt-2 text-center">TO</p>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="range[1]"
                  label="Max"
                  outlined
                  dense
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- <v-divider></v-divider>
            <v-card-title class="pb-0">Customer Rating</v-card-title>
            <v-container class="pt-0"  fluid>
              <v-checkbox append-icon="mdi-star" label="4 & above" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="3 & above" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="2 & above" hide-details dense></v-checkbox>
              <v-checkbox append-icon="mdi-star" label="1 & above" hide-details dense></v-checkbox>
            </v-container>
            <v-divider></v-divider>
            <v-card-title class="pb-0">Size</v-card-title>
            <v-container class="pt-0" fluid>
              <v-checkbox  label="XS" hide-details dense></v-checkbox>
              <v-checkbox  label="S" hide-details dense></v-checkbox>
              <v-checkbox  label="M" hide-details dense></v-checkbox>
              <v-checkbox  label="L" hide-details dense></v-checkbox>
              <v-checkbox  label="XL" hide-details dense></v-checkbox>
              <v-checkbox  label="XXL" hide-details dense></v-checkbox>
              <v-checkbox  label="XXXL" hide-details dense></v-checkbox>
            </v-container> -->

          </v-card>
        </div>
        <div
          class="col-md-9 col-sm-9 col-xs-12"
        >

          <v-breadcrumbs class="pb-0" :items="breadcrums">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="toLink(item.href)"
                :disabled="item.disabled"
                >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>

          <v-row dense>
            <v-col cols="12" sm="8" class="pl-6 pt-6">
              <medium>Hiển thị {{page * 16 - 16}}-{{page * 16 <= totalProd ? page * 16 : totalProd}} của {{totalProd}} sản phẩm</medium>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select class="pa-0" v-model="select" :items="options" style="margin-bottom: -20px;" outlined dense></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <div class="row text-center">
            <div class="col-md-3 col-sm-6 col-xs-12 col-6" :key="pro.id" v-for="(pro, index) in products">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="mx-auto"
                  color="grey lighten-4"
                  max-width="600"
                >
                  <v-img
                    class="white--text align-end img-product"
                    :aspect-ratio="16/9"
                    height="200px"
                    :src="getImg(pro['img'], pro, index)"
                  >
                    <!-- <v-card-title>{{pro.type}} </v-card-title> -->
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn v-if="hover" @click="toDetailProduct(pro, index)" class="" outlined>Chi tiết</v-btn>
                      </div>

                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="text--primary">
                    <div><a @click="toDetailProduct(pro, index)" style="text-decoration: none; color: blue; font-weight: bold;">{{pro['name']}}</a></div>
                    <div>{{pro['chungLoai']}}</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>

            <div class="text-center" v-if="!products['length']" style="width: 100%;">
              Không tìm thấy dữ liệu!
            </div>
          </div>
          <div class="text-center mt-12">
            <v-pagination
              v-model="page"
              :length="lengthPage"
              :total-visible="7"
              circle
            ></v-pagination>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }
</style>
<script>
    import manufacturers from '@/plugins/hangSanXuat.json'
    import productTypes from '@/plugins/loaiSanPham.json'
    import productItems from '@/plugins/sanPham.json'
    export default {
        data: () => ({
            lengthPage: 0,
            totalProd: 0,
            indexSelectLoai: -1,
            indexSelectHang: -1,
            range: [0, 10000],
            select:'Mặc định',
            options: [
                'Mặc định',
                'Phổ biến',
                'Giá: Thấp đến cao',
                'Giá: Cao xuống thấp',
            ],
            page:1,
            breadcrums: [
                {
                    text: 'Trang chủ',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Sản phẩm',
                    disabled: false,
                    href: 'shop',
                }
            ],
            min:0,
            max:10000,
            danhMucItems: productTypes['items'],
            hangItems: manufacturers['items'],
            items: [
                {
                    id: 2,
                    name: 'Shoes',
                    children: [
                        { id: 2, name: 'Casuals' },
                        { id: 3, name: 'Formals' },
                        { id: 4, name: 'Sneakers' },
                    ],
                },
                {
                    id: 1,
                    name: 'Clothing',
                    children: [
                        { id: 5, name: 'Shirts' },
                        { id: 6, name: 'Tops' },
                        { id: 7, name: 'Tunics' },
                        { id: 8, name: 'Bodysuit' },
                    ],
                }
            ],
            products: []
        }),
        created () {
          let vm = this
          vm.$nextTick(function () {
            let query = vm.$router.history.current.query
            // console.log('query query', query)
            if (query && query['page']) {
              vm.page = parseInt(query['page']) || 1
            } else {
              vm.page = 1
            }
            vm.pagging(query['idType'], query['idProd'], query['search'])
            window.scrollTo(0, 0);
          })
        },
        watch: {
          page (val) {
            let vm = this
            let query = vm.$router.history.current.query
            vm.pagging(query['idType'], query['idProd'], query['search'])
            vm.$router.push({
              path: '/shop',
              query: {
                idType: query['idType'] || '',
                idProd: query['idProd'] || '',
                page: val
              }
            })
          },
          '$route': function (newRoute, oldRoute) {
            let vm = this
            let query = newRoute.query
            if (query && query['page']) {
              this.page = parseInt(query['page']) || 1
            } else {
              this.page = 1
            }
            vm.pagging(query['idType'], query['idProd'], query['search'])
          }
        },
        methods: {
          toLink (link) {
            let vm = this
            if (link) {
              vm.$router.push({
                path: link
              })
            }
          },
          pagging (loaiSp, hangSx, searchName) {
            let vm = this
            let tmp = []
            if (loaiSp) {
              let itemLoaiSp = productItems.filter(item => {
                return item['chungLoaiCode'] === loaiSp
              }) || []
              tmp = itemLoaiSp
              let indexLoaiSp = vm.danhMucItems.findIndex(itemDm => {
                return itemDm['code'] === loaiSp
              })
              // console.log('vm.indexLoaiSp===========', indexLoaiSp)
              vm.indexSelectLoai = indexLoaiSp
              vm.indexSelectHang = -1
            }
            if (hangSx) {
              let itemHangSx = productItems.filter(item => {
                return item['hang'] === hangSx
              }) || []
              tmp = itemHangSx
              let indexHangSx = vm.hangItems.findIndex(itemH => {
                return itemH['code'] === hangSx
              })
              // console.log('vm.hangSx===========', hangSx)
              // console.log('vm.indexHangSx===========', indexHangSx)
              vm.indexSelectHang = indexHangSx
              vm.indexSelectLoai = -1
            }
            if (searchName) {
              let itemSearch = productItems.filter(item => {
                if (item['name'].indexOf(searchName) !== -1) {
                  return true
                } else {
                  return false
                }
              }) || []
              tmp = itemSearch
            }
            if (!loaiSp && !hangSx && !searchName) {
              tmp = productItems
            }
            vm.lengthPage = Math.ceil((tmp['length'] || 0) / 16);
            vm.totalProd = tmp['length']
            let from = vm.page * 16 - 16
            let to = vm.page * 16
            if (to > vm.totalProd - 1) {
              to = vm.totalProd
            }
            let tmpDataPage = []
            // console.log('vm.page===========', vm.page)
            // console.log('vm.from===========', from)
            // console.log('vm.to===========', to)
            for (var i = from; i < to; i++) {
              tmpDataPage.push(tmp[i])
            }
            vm.products = tmpDataPage || []
            // console.log('vm.tmpDataPage===========', tmpDataPage)
            // console.log('vm.tmp===========', tmp)
          },
          getImg (img, item, index) {
            let vm = this
            if (item['imgCode']) {
              return require('../assets/img/shop/' + img + '_' + item['imgCode'] + '.' + item['imgType'])
            }
            let bacDanCauMax = 4
            let bacDanCauDoChanMax = 5
            let bacDanCauTuLuaMax = 4
            let bacDanChanTrucMax = 4
            let bacDanChanTrucTangChong = 5
            let bacDanConMax = 4
            let bacDanDua = 4
            let bacDanKimMax = 5
            let bacDanTangChongMax = 4
            let bacDanTiepXuc4DiemMax = 4
            let bacDanTiepXucGoc2Day = 4
            let tmpRd = 0
            if (img === 'bac_dan_cau') {
              tmpRd = Math.floor(Math.random() * bacDanCauMax) + 1
            } else if (img === 'bac_dan_cau_do_chan') {
              tmpRd = Math.floor(Math.random() * bacDanCauDoChanMax) + 1
            } else if (img === 'bac_dan_cau_tu_lua') {
              tmpRd = Math.floor(Math.random() * bacDanCauTuLuaMax) + 1
            } else if (img === 'bac_dan_chan_truc') {
              tmpRd = Math.floor(Math.random() * bacDanChanTrucMax) + 1
            } else if (img === 'bac_dan_chan_truc_tang_chong') {
              tmpRd = Math.floor(Math.random() * bacDanChanTrucTangChong) + 1
            } else if (img === 'bac_dan_con') {
              tmpRd = Math.floor(Math.random() * bacDanConMax) + 1
            } else if (img === 'bac_dan_dua') {
              tmpRd = Math.floor(Math.random() * bacDanDua) + 1
            } else if (img === 'bac_dan_kim') {
              tmpRd = Math.floor(Math.random() * bacDanKimMax) + 1
            } else if (img === 'bac_dan_tang_chong') {
              tmpRd = Math.floor(Math.random() * bacDanTangChongMax) + 1
            } else if (img === 'bac_dan_tiep_xuc_4_diem') {
              tmpRd = Math.floor(Math.random() * bacDanTiepXuc4DiemMax) + 1
            } else if (img === 'bac_dan_tiep_xuc_goc_2_day') {
              tmpRd = Math.floor(Math.random() * bacDanTiepXucGoc2Day) + 1
            }
            item['imgCode'] = tmpRd
            return require('../assets/img/shop/' + img + '_' + tmpRd + '.' + item['imgType'])
          },
          toDetailProduct (item, index) {
            let vm = this
            window.localStorage.setItem("product", JSON.stringify(item) || '');
            vm.$router.push({
              path: '/product',
              query: {
                name: item['name']
              }
            })
          },
          toDetail (item, index) {
            let vm = this
            // vm.products = productItems.filter(itemProd => {
            //   return itemProd['chungLoaiCode'] === item['code']
            // })
            vm.indexSelectLoai = index
            vm.indexSelectHang = -1
            vm.page = 1
            // vm.pagging(item['code'], '', '')
            if (item['code'] === 'PKVB') {
              vm.$router.push({
                path: '/accessories',
                query: {
                  page: 1
                }
              })
            } else {
              vm.$router.push({
                path: '/shop',
                query: {
                  idType: item['code'] || '',
                  page: 1
                }
              })
            }
            // console.log('vm.toDetailHang==========', item)
          },
          toDetailHang (item, index) {
            let vm = this
            vm.products = productItems.filter(itemProd => {
              return itemProd['hang'] === item['code']
            })
            vm.page = 1
            // vm.pagging('', item['code'], '')
            vm.indexSelectHang = index
            vm.indexSelectLoai = -1
            vm.$router.push({
              path: '/shop',
              query: {
                idProd: item['code'] || '',
                page: 1
              }
            })
            // console.log('vm.toDetailHang==========', item)
          }
        }
    }
</script>

<style>
  .item_list:hover {
    background: #ccc !important;
  }
  .v-list-item.v-list-item--link.theme--light:hover {
    background: #dbdbdb;
  }
  .item_list.v-list-item.v-list-item--link.theme--light.active {
    background: #dbdbdb;
  }
  .nav__icon {
    display: none;
  }
  .space-nav {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    /*div.hang-san-xuat {
      display: none;
    }*/

    .img-product {
      height: 120px !important;
    }
    .list-category {
      height: 250px;
      overflow: auto;
    }
    .list-company {
      height: 250px;
      overflow: auto;
    }

    .whyBox {
      padding: 15px 0px !important;
    }

    .whyBox ._item {
      padding-top: 0;
      padding-left: 22px;
      padding-right: 20px;
    }

    .whyBox .box-heading {
      font-size: 20px;
    }
    .whyBox .box-website {
      font-size: 19px !important;
    }

    div.whyBox > .col-12.col-md-12.col-sm-12.col-xs-12.text-center {
      padding-left: 10px;
      padding-right: 10px;
    }

    div.hang-san-xuat {
      height: 250px;
      overflow: auto;
      padding-top: 0;
    }

    div.hang-san-xuat .v-responsive.v-image.white--text.align-end {
      height: 70px !important;
    }
    div.v-toolbar__title {
      width: 90px !important;
    }
    .v-responsive.v-image.v-carousel__item {
      height: 150px !important;
    }
    .v-window.v-item-group.theme--dark.v-window--show-arrows-on-hover.v-carousel.v-carousel--hide-delimiter-background {
      height: 150px !important;
    }
    .img-type-home {
      height: 120px !important;
    }
    .__nav {
      display: none !important;
    }
    .img-footer-logo {
      height: 80px !important;
    }
    .space-nav {
      height: 116%;
      width: 1px;
      background: #ccc;
      padding-left: 1px;
      margin-left: 5px;
      margin-right: 12px;
    }
    .nav__icon {
      display: block;
    }
    .space-nav {
      display: block;
    }
    .v-toolbar__content {
      padding-right: 6px !important;
    }
  }
</style>
