<template>
  <div>
    <v-container>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 py-0">
          <v-breadcrumbs class="pb-0" :items="breadcrums">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item @click="toLink(item.href)" :disabled="item.disabled">
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12" v-show="!stateDetail">
          <v-data-table :headers="headers" :items="products" :page.sync="page" :items-per-page="25" hide-default-footer
            class="elevation-1" @page-count="pageCount = $event">
            <template v-slot:top>
              <tr class="pt-2">
                <td class="text-xs-center" style="width: 12%;">
                  <v-text-field placeholder="Mã quốc tế" class="my-0 pt-2" v-model="searchMaQuocTe"></v-text-field>
                </td>
                <td class="text-xs-center" style="width: 10%;">
                  <v-text-field placeholder="Mã của Nga" class="my-0 py-0" v-model="searchMaCuaNga"></v-text-field>
                </td>
                <td class="text-xs-center" style="width: 21%;">
                  <v-autocomplete clearable placeholder="Kiểu" :items="kieuItems" v-model="searchKieu"></v-autocomplete>
                </td>
                <td class="text-xs-center" style="width: 13%;">
                  <v-autocomplete clearable placeholder="Hãng" :items="hangItems" v-model="searchHang"></v-autocomplete>
                </td>
                <td class="text-xs-center" style="width: 4%;">
                </td>
                <td class="text-xs-center" style="width: 10%;">
                  <v-text-field placeholder="d (mm)" class="my-0 py-0" v-model="searchd"></v-text-field>
                </td>
                <td class="text-xs-center" style="width: 10%;">
                  <v-text-field placeholder="D (mm)" class="my-0 py-0" v-model="searchD"></v-text-field>
                </td>
                <td class="text-xs-center" style="width: 10%;">
                  <v-text-field placeholder="B (mm)" class="my-0 py-0" v-model="searchB"></v-text-field>
                </td>
                <td class="text-xs-center px-0">
                  <v-btn color="primary" small @click="filter()">Tìm</v-btn>
                  <v-btn color="primary" small @click="clearFilter()">Xóa</v-btn>
                </td>
              </tr>
            </template>
            <!-- <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item['maQuocTe'] }}</td>
              <td class="text-xs-center">{{ props.item['maCuaNga'] }}</td>
              <td class="text-xs-center">{{ props.item['kieu'] }}</td>
              <td class="text-xs-center">{{ props.item['hang'] }}</td>
              <td class="text-xs-center">{{ props.item['maTheoHang'] }}</td>
              <td class="text-xs-center">{{ props.item['d'] }}</td>
              <td class="text-xs-center">{{ props.item['D'] }}</td>
              <td class="text-xs-center">{{ props.item['B'] }}</td>
              <td class="text-xs-center">Chi tiết</td>
            </template> -->
            <template v-slot:item.detail="{ item }">
              <span style="cursor: pointer; color: #044D7F;" @click="toDetail(item)">Chi tiết</span>
            </template>
          </v-data-table>
          <div style="width: 100%;" class="pt-3">
            <v-pagination v-model="page" :total-visible="7" :length="pageCount" circle></v-pagination>
          </div>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-12" v-show="stateDetail" style="width: 100%;">
          <table cellpadding="0" cellspacing="1" id="pro">
            <tbody>
              <tr>
                <th colspan="4">
                  <div class="name-bearings-detail py-2">{{ modelProduct['maQuocTe'] }} / {{ modelProduct['maCuaNga'] }}
                  </div>
                </th>
              </tr>
              <tr>
                <td class="bt">Kiểu：</td>
                <td>{{ modelProduct['kieu'] }}</td>
                <td class="bt">Chi tiết ：</td>
                <td> -</td>
              </tr>
              <tr>
                <td class="bt">Mã quốc tế ：</td>
                <td>{{ modelProduct['maQuocTe'] }}</td>
                <td class="bt">Mã của Nga：</td>
                <td>{{ modelProduct['maCuaNga'] }}</td>
              </tr>
              <tr>
                <td class="bt">Hãng：</td>
                <td>{{ modelProduct['hang'] }}</td>
                <td class="bt">Mã theo hãng ：</td>
                <td>{{ modelProduct['maTheoHang'] }}</td>
              </tr>
              <tr>
                <td class="bt">d：</td>
                <td>{{ modelProduct['d'] }}</td>
                <td class="bt">D：</td>
                <td>{{ modelProduct['D'] }}</td>
              </tr>
              <tr>
                <td class="bt">B：</td>
                <td>{{ modelProduct['B'] }}</td>
                <td class="bt">Note：</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12" v-show="stateDetail">
          <v-card>
            <div style="border: 1px solid#ccc; width: 100%; padding: 12px; margin-top: 50px;">
              <div class="name-product-detail">Liên hệ ngay </div>

              <div>
                <a href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                  <v-icon style="font-size: 17px; color: #df900d;">mdi-phone-classic</v-icon>
                  <span style="font-family: 'Bebas Neue'; font-size: 16px; color:#e30018;">0942 332 228</span>
                </a>
              </div>
              <div>
                <v-icon style="font-size: 17px; color: #df900d;">mdi-email</v-icon>

                <a href="mailto:vongbicongnghiep.gbp@gmail.com"
                  style="color: #e30018; font-size: 13px;">vongbicongnghiep.gbp@gmail.com</a>
              </div>
            </div>
          </v-card>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-12" v-show="stateDetail">
          <v-card>
            <div class="description-content">
              <b>Giới thiệu </b> <br>
              <p align="center">
                <img src="https://gib.com.vn/images/bearing_images/dzwqmqzc.jpg" width="635" height="220">
              </p>
              <p> Để biết thêm thông thông tin cũng như giá bán của sản phẩm theo từng thời điểm xin vui lòng liên hệ với
                phòng kinh doanh của chúng tôi..<br>
              </p>
            </div>
          </v-card>
        </div>
      </div>

    </v-container>
    <v-card class="hotline">
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-12 col-sm-12 text-center">
            <div class="container">
              <span class="dot" style="margin-left: 5px; margin-top: -4px;">
                <span class="ping"></span>
              </span>
              <span style="font-weight:300; color: #fff;">Hotline hỗ trợ:</span>
              <!-- <i class="fas fa-phone-square" style="color:#38c500;padding-left: 18px"></i> -->
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">024 668 42879</span>
              </a>
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">0916 045 428</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import productItems from '@/plugins/sanPham.json'
import accessoriesItems from '@/plugins/phuKien.json'
export default {
  data: () => ({
    pageCount: accessoriesItems['length'],
    stateDetail: false,
    rating: 4.5,
    page: 1,
    kieuItems: [
      'angular-contact ball bearing',
      'cylindrical roller bearing',
      'Cylindrical Roller Thrust Bearings',
      'Deep Groove Ball Bearings',
      'Idler Wheel Bearings',
      'Linear Motion Bearings',
      'Miniature bearings',
      'Needle Roller and Cage Components',
      'Needle roller bearings',
      'Needle Roller Combination Bearings',
      'Non-standard bearing',
      'Others',
      'Pillow Block Bearings',
      'self-aligning ball bearing',
      'Spherical Plain Bearings',
      'Spherical Roller Bearings',
      'tapered roller bearing',
      'Thrust angular-contact ball bearing',
      'Thrust ball Bearings',
      'Thrust Roller Bearings'
    ],
    searchKieu: '',
    searchHang: '',
    searchMaCuaNga: '',
    searchMaHang: '',
    searchd: '',
    searchD: '',
    searchB: '',
    hangItems: [
      'AET',
      'ASAHI',
      'ASK',
      'BARDEN',
      'DKF',
      'EASE',
      'FAFNIR',
      'FAG',
      'FLT',
      'FYH',
      'GMN',
      'IKO',
      'INA',
      'Kingon',
      'KOYO',
      'MAC',
      'MRC',
      'NACHI',
      'NMB',
      'NSK',
      'NTN',
      'RHP',
      'RIV',
      'SND',
      'SNF',
      'SNFA',
      'SNR',
      'STEYR',
      'SYEYR',
      'THK',
      'TIMKEN',
      'TORRINGTON',
      'ZKL'
    ],
    searchMaQuocTe: '',
    headers: [
      {
        text: 'Mã quốc tế',
        align: 'center',
        sortable: false,
        value: 'maQuocTe',
      },
      { text: 'Mã của Nga', value: 'maCuaNga', align: 'center', sortable: false },
      { text: 'Kiểu', value: 'kieu', align: 'center', sortable: false },
      { text: 'Hãng', value: 'hang', align: 'center', sortable: false },
      { text: 'Mã theo hãng', value: 'maTheoHang', align: 'center', sortable: false },
      { text: 'd', value: 'd', align: 'center', sortable: false },
      { text: 'D', value: 'D', align: 'center', sortable: false },
      { text: 'B', value: 'B', align: 'center', sortable: false },
      { text: 'Chi tiết', value: 'detail', align: 'center', sortable: false }
    ],
    modelProduct: {},
    products: accessoriesItems,
    breadcrums: [
      {
        text: 'Trang chủ',
        disabled: false,
        href: '/',
      },
      {
        text: 'Sản phẩm',
        disabled: false,
        href: '/shop?page=1',
      },
      {
        text: 'Phụ kiện vòng bi',
        disabled: false,
        href: '/accessories',
      }
    ],
    item: 5
  }),
  created() {
    let vm = this
    vm.$nextTick(function () {
      let query = vm.$router.history.current.query
      console.log('query query', query)
      if (query && query['page']) {
        if (parseInt(query['page']) && parseInt(query['page']) !== 1) {
          vm.page = 1
        }
      }
      if (query && query['id']) {
        vm.stateDetail = true
      }
      if (query && query['maQuocTe']) {
        vm.searchMaQuocTe = query['maQuocTe']
      }
      if (query && query['maCuaNga']) {
        vm.searchMaCuaNga = query['maCuaNga']
      }
      if (query && query['kieu']) {
        vm.searchKieu = query['kieu']
      }
      if (query && query['hang']) {
        vm.searchHang = query['hang']
      }
      if (query && query['d']) {
        vm.searchd = query['d']
      }
      if (query && query['D']) {
        vm.searchD = query['D']
      }
      if (query && query['B']) {
        vm.searchB = query['B']
      }
      vm.filter(vm.page)
      window.scrollTo(0, 0);
    })
  },
  watch: {
    'page'(val) {
      let vm = this
      let tmpQuery = {
        maQuocTe: vm.searchMaQuocTe,
        maCuaNga: vm.searchMaCuaNga,
        kieu: vm.searchKieu,
        hang: vm.searchHang,
        d: vm.searchd,
        D: vm.searchD,
        B: vm.searchB
      }
      vm.$router.push({
        path: '/accessories',
        query: {
          ...tmpQuery,
          page: val
        }
      })
    },
    '$route': function (newRoute, oldRoute) {
      let vm = this
      let query = newRoute['query']
      if (query['id']) {
        vm.stateDetail = true
      } else {
        vm.stateDetail = false
        let index = vm.breadcrums.findIndex(item => {
          return item['disabled'] === true
        })
        if (index !== -1) {
          vm.breadcrums.splice(index, 1)
        }
      }
    }
  },
  methods: {
    toLink(link) {
      let vm = this
      if (link) {
        vm.$router.push({
          path: link
        })
      }
    },
    toDetail(item) {
      console.log(item)
      let vm = this
      vm.modelProduct = item
      vm.stateDetail = true
      let tmpQuery = {
        maQuocTe: vm.searchMaQuocTe,
        maCuaNga: vm.searchMaCuaNga,
        kieu: vm.searchKieu,
        hang: vm.searchHang,
        d: vm.searchd,
        D: vm.searchD,
        B: vm.searchB,
        page: vm.page
      }
      vm.$router.push({
        path: '/accessories',
        query: {
          id: item['maQuocTe'] || '',
          ...tmpQuery,
          page: vm.page
        }
      })
      vm.breadcrums.push({
        text: item['maQuocTe'],
        disabled: true
      })
    },
    getRandomItem() {
      let vm = this
      let query = vm.$router.history.current.query
      let length = productItems['length']
      let tmp = []
      if (!length) {
        vm.products = []
        return
      }
      if (query['id']) {
        tmp = productItems.filter(item => {
          return item['chungLoaiCode'] === query['id']
        })
      }
    },
    clearFilter() {
      let vm = this
      vm.products = accessoriesItems
      vm.page = 1
      vm.searchMaQuocTe = ''
      vm.searchMaCuaNga = ''
      vm.searchKieu = ''
      vm.searchHang = ''
      vm.searchd = ''
      vm.searchD = ''
      vm.searchB = ''
      vm.pageCount = Math.ceil((accessoriesItems['length'] || 0) / 25);
      vm.$router.push({
        path: '/accessories',
        query: {
          page: vm.page
        }
      })
    },
    filter(page) {
      let vm = this
      let tmp = accessoriesItems
      let flag = false
      if (vm.searchMaQuocTe) {
        tmp = tmp.filter(item => {
          if (item['maQuocTe'].indexOf(vm.searchMaQuocTe) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchMaCuaNga) {
        tmp = tmp.filter(item => {
          if (item['maCuaNga'].indexOf(vm.searchMaCuaNga) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchKieu) {
        tmp = tmp.filter(item => {
          if (item['kieu'] === vm.searchKieu) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchHang) {
        tmp = tmp.filter(item => {
          if (item['hang'].indexOf(vm.searchHang) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchd) {
        tmp = tmp.filter(item => {
          if (item['d'].indexOf(vm.searchd) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchD) {
        tmp = tmp.filter(item => {
          if (item['D'].indexOf(vm.searchD) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      if (vm.searchB) {
        tmp = tmp.filter(item => {
          if (item['B'].indexOf(vm.searchB) !== -1) {
            return true
          } else {
            return false
          }
        })
        flag = true
      }
      vm.products = tmp
      vm.page = page || 1
      vm.pageCount = Math.ceil((tmp['length'] || 0) / 25);
      let tmpQuery = flag ? {
        maQuocTe: vm.searchMaQuocTe,
        maCuaNga: vm.searchMaCuaNga,
        kieu: vm.searchKieu,
        hang: vm.searchHang,
        d: vm.searchd,
        D: vm.searchD,
        B: vm.searchB
      } : {}
      vm.$router.push({
        path: '/accessories',
        query: {
          ...tmpQuery,
          page: vm.page
        }
      })
    },
    getImg(img, item, index) {
      let vm = this
      let bacDanCauMax = 4
      let bacDanCauDoChanMax = 5
      let bacDanCauTuLuaMax = 4
      let bacDanChanTrucMax = 4
      let bacDanChanTrucTangChong = 5
      let bacDanConMax = 4
      let bacDanDua = 4
      let bacDanKimMax = 5
      let bacDanTangChongMax = 4
      let bacDanTiepXuc4DiemMax = 4
      let bacDanTiepXucGoc2Day = 4
      let tmpRd = 0
      if (img === 'bac_dan_cau') {
        tmpRd = Math.floor(Math.random() * bacDanCauMax) + 1
      } else if (img === 'bac_dan_cau_do_chan') {
        tmpRd = Math.floor(Math.random() * bacDanCauDoChanMax) + 1
      } else if (img === 'bac_dan_cau_tu_lua') {
        tmpRd = Math.floor(Math.random() * bacDanCauTuLuaMax) + 1
      } else if (img === 'bac_dan_chan_truc') {
        tmpRd = Math.floor(Math.random() * bacDanChanTrucMax) + 1
      } else if (img === 'bac_dan_chan_truc_tang_chong') {
        tmpRd = Math.floor(Math.random() * bacDanChanTrucTangChong) + 1
      } else if (img === 'bac_dan_con') {
        tmpRd = Math.floor(Math.random() * bacDanConMax) + 1
      } else if (img === 'bac_dan_dua') {
        tmpRd = Math.floor(Math.random() * bacDanDua) + 1
      } else if (img === 'bac_dan_kim') {
        tmpRd = Math.floor(Math.random() * bacDanKimMax) + 1
      } else if (img === 'bac_dan_tang_chong') {
        tmpRd = Math.floor(Math.random() * bacDanTangChongMax) + 1
      } else if (img === 'bac_dan_tiep_xuc_4_diem') {
        tmpRd = Math.floor(Math.random() * bacDanTiepXuc4DiemMax) + 1
      } else if (img === 'bac_dan_tiep_xuc_goc_2_day') {
        tmpRd = Math.floor(Math.random() * bacDanTiepXucGoc2Day) + 1
      }
      return require('../assets/img/shop/' + img + '_' + tmpRd + '.' + item['imgType'])
    }
  }
}
</script>

<style>
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.name-product-detail {
  color: #044d7f;
  font-size: 24px;
  font-weight: normal;
}

.theme--light.v-data-table thead tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, .12);
  background: #d9d9d9;
}

table#pro td {
  padding: 8px 3px !important;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table#pro {
  border-collapse: collapse;
  width: 100%;
  color: #484646;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

table#pro th {
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.name-bearings-detail {
  background: #ccc;
  text-align: center;
  font-size: 20px;
  color: #167fc1;
}

.description-content {
  text-align: justify;
  padding: 8px;
  border-bottom: 1px solid#ddd;
  border-top: 1px solid#ddd;
}
</style>
