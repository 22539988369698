<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" absolute left>
      <v-list style="margin-top: 50px;">
        <v-list-item-group active-class="deep-purple--text text--accent-4">

          <v-list-item>
            <v-list-item-title>
              <v-text-field @keyup.enter="searchItem()" v-model="search" prepend-inner-icon="mdi-magnify"
                label="Tìm kiếm" />
            </v-list-item-title>
          </v-list-item>

          <v-list-item href="/">
            <v-list-item-title>TRANG CHỦ</v-list-item-title>
          </v-list-item>

          <v-list-item @click="toLink('/introduce')">
            <v-list-item-title>GIỚI THIỆU</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <div class="pb-2" @click="menu = !menu">LOẠI SẢN PHẨM <v-icon>arrow-bottom-drop-circle-outline</v-icon>
              </div>
              <div v-if="menu">
                <div class="item-nav py-2 pl-2" style="cursor: pointer;" v-for="(item, index) in loaiSanPhamItems"
                  :key="index + '__nav'" @click="toLink('/shop?idType=' + item['code']);">
                  <v-list-item-title class="nav-header" style="text-transform: uppercase; font-weight: normal;">{{
                    item.text }}</v-list-item-title>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="toLink('/document')">
            <v-list-item-title>TÀI LIỆU KỸ THUẬT</v-list-item-title>
          </v-list-item>

          <v-list-item href="/">
            <v-list-item-title>TUYỂN DỤNG</v-list-item-title>
          </v-list-item>

          <v-list-item @click="toLink('/news')">
            <v-list-item-title>TIN TỨC</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app style="background: #F2F2F2 !important;" color="primary"
      dark>
      <v-toolbar-title style="width: 350px">
        <a href="/" class="white--text" style="text-decoration: none;">
          <v-img width="87px" height="90px" contain class="white--text align-end"
            :src="require('../assets/img/layout/GBP_knen.png')">
          </v-img>
        </a>
      </v-toolbar-title>

      <v-text-field @keyup.enter="searchItem()" v-model="search" flat solo-inverted hide-details
        prepend-inner-icon="mdi-magnify" label="Tìm kiếm" class="hidden-sm-and-down pl-10 ml-4 text-color" />


      <v-spacer />

      <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
        <v-icon class="icon-phone-number"
          style="font-size: 17px; padding-left: 18px; color: #044E7F;">mdi-phone-classic</v-icon>
        <span class="phone-number" style="font-family: 'Bebas Neue'; font-size: 16px; color: #044E7F; position: relative; top: 3px; left: 4px;">024 668
          42879</span>
      </a>
      <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
        <v-icon class="icon-phone-number"
          style="font-size: 17px; padding-left: 18px; color: #044E7F;">mdi-phone-classic</v-icon>
        <span class="phone-number" style="font-family: 'Bebas Neue'; font-size: 16px; color: #044E7F; position: relative; top: 3px; left: 4px;">0916 045 428</span>
      </a>

      <div class="space-nav"></div>

      <v-app-bar-nav-icon class="pr-4 nav__icon" style="color: #ccc;" @click.stop="drawerFn()"></v-app-bar-nav-icon>

    </v-app-bar>

    <v-main>
      <v-bottom-navigation :value="activeBtn" color="primary" horizontal class="__nav">
        <a href="/" class="v-btn">
          <span class="nav-header" style="font-weight: normal;">TRANG CHỦ</span>
        </a>
        <a @click="toLink('/introduce')" class="v-btn pl-2">
          <span class="nav-header" style="font-weight: normal;">GIỚI THIỆU</span>
        </a>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <span class="nav-header" style="font-weight: normal;">LOẠI SẢN PHẨM</span>
            </v-btn>
          </template>
          <v-card class="mx-auto" max-width="344" outlined>
            <v-list-item v-for="(item, index) in loaiSanPhamItems" :key="index"
              @click="toLink('/shop?idType=' + item['code'])">
              <v-list-item-title class="nav-header" style="text-transform: uppercase; font-weight: normal;">{{ item.text
              }}</v-list-item-title>
            </v-list-item>

          </v-card>
        </v-menu>
        <a @click="toLink('/document')" class="v-btn pr-3">
          <span class="nav-header" style="font-weight: normal;">TÀI LIỆU KỸ THUẬT</span>
        </a>
        <a href="/" class="v-btn">
          <span class="nav-header" style="font-weight: normal;">TUYỂN DỤNG</span>
        </a>
        <v-btn @click="toLink('/news')">
          <span class="nav-header" style="font-weight: normal;">TIN TỨC</span>
        </v-btn>
      </v-bottom-navigation>
    </v-main>

    <router-view />
    <v-footer :padless="true">
      <v-card style="background-color: #DDE8EF!important; border-color: #DDE8EF!important;" flat tile width="100%"
        class="secondary white--text text-center">

        <v-card-text class="white--text pt-0">
          <v-container>
            <div class="row">
              <div class="col-12 col-md-2 col-sm-3 col-xs-12 text-center">
                <v-img contain class="white--text align-end img-footer-logo"
                  :src="require('../assets/img/layout/GBP_knen.png')">
                </v-img>
              </div>
              <div class="col-12 col-md-5 col-sm-3 col-xs-12 company-footer text-left">
                <p style="">CÔNG TY CỔ PHẦN THIẾT BỊ CÔNG NGHIỆP GBP VIỆT NAM</p>

                <p style="">Số ĐKKD&nbsp;<span>0109457997</span>&nbsp;do Sở KHĐT Tp. Hà Nội&nbsp;</p>

                <p style="">Địa chỉ: <span style="">BT5 Khu Đô Thị Cầu Bươu, Tân Triều, Thanh Trì, Hà Nội</span></p>

                <p style="">Điện thoại: &nbsp;024 668 42879 &nbsp;&nbsp; Hotline: &nbsp;0916 045 428</p>

                <p style="">Mail: <span style="">vongbicongnghiep.gbp@gmail.com</span></p>

                <p style="">Website: <a href="https://gbp.com.vn" style="">https://gbp.com.vn</a></p>

              </div>
              <div class="col-12 col-md-5 col-sm-3 col-xs-12 text-right company-footer">
                <span><a href="/">Trang chủ</a></span> |
                <span><a href="/">Giới thiệu</a></span> |
                <span><a to="/shop">Sản phẩm</a></span> |
                <span><a to="/document">Tài liệu kỹ thuật</a></span> |
                <span><a href="javascript:;">Tuyển dụng</a></span> |
                <span><a href="javascript:;">Liên hệ</a></span>

                <div style="color: #333; font-size: 12px;">Bản quyền thuộc về GBP Việt Nam</div>
                <p>Thiết kế bởi <a href="http://bbbgroup.vn/">Sunny</a></p>
                <a href="javascript:;"></a>
              </div>
            </div>
          </v-container>

        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import productTypes from '@/plugins/loaiSanPham.json'
import productItems from '@/plugins/sanPham.json'
export default {
  data() {
    return {
      menu: false,
      drawer: false,
      search: '',
      loaiSanPhamItems: productTypes['items'],
      searchItems: [],
      activeBtn: 1,
    }
  },
  watch: {
  },
  methods: {
    drawerFn() {
      this.drawer = !this.drawer;
      window.scrollTo(0, 0);
    },
    searchItem() {
      let vm = this
      vm.$router.push({
        path: '/shop',
        query: {
          search: vm.search
        }
      })
    },
    toLink(link) {
      let vm = this
      if (link) {
        vm.$router.push({
          path: link
        })
      }
    },
    toDetailProduct(item, index) {
      let vm = this
      window.localStorage.setItem("product", JSON.stringify(item) || '');
      vm.$router.push({
        path: '/product',
        query: {
          name: item['name']
        }
      })
    },
    on() {

    }
  }
}
</script>

<style>
.v-application {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 14px;
  font-weight: 300;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.company-footer p {
  margin-bottom: 0px;
  color: #333;
  font-size: 13px;
}

.company-footer {
  color: #333;
}

.company-footer a {
  font-size: 13px;
  color: #333;
}

.hotline {
  background-color: #179f6d !important;
  border-color: #3ae3d6 !important;
}

.nav-header {
  font-weight: bold;
  font-size: 13px;
  color: #2d3753;
}

.text-color input {
  color: #044E7F;
}

.v-text-field.v-text-field--solo .v-label {
  color: #044E7F;
}

.theme--dark.v-icon {
  color: #044E7F;
}

.nav__icon {
  display: none !important;
}

.space-nav {
  display: none !important;
}

.item-nav:hover {
  background: #ccc !important;
  color: yellow !important;
}

@media only screen and (max-width: 600px) {

  /*div.hang-san-xuat {
      display: block;
    }*/
  div.v-toolbar__title {
    width: 90px !important;
  }

  .__nav {
    display: none !important;
  }

  .img-footer-logo {
    height: 80px !important;
  }

  .space-nav {
    height: 116%;
    width: 1px;
    background: #ccc;
    margin-left: 15px;
    margin-right: 12px;
  }

  .img-product {
    height: 120px !important;
  }

  .nav__icon {
    display: block !important;
  }

  .space-nav {
    display: block !important;
  }

  .item-nav:hover {
    background: #ccc;
    color: yellow;
  }

  .phone-number {
    font-size: 13px !important;
  }

  .icon-phone-number {
    font-size: 13px !important;
  }
}
</style>
