<template>
  <div>
    <v-container>
      <div class="row">
        <div
         class="col-md-3 col-sm-3 col-xs-12"
        >
          <v-card outlined>
            <v-card-title class="py-2" style="color: #fff; background: #044D7F; font-size: 17px;">Tin tức</v-card-title>
            <v-list class="pt-0">
              <template v-for="(item, index) in tinTucItems">
                <v-divider :key="item['title'] + index"></v-divider>
                <v-list-item
                  style="text-decoration: none; text-transform: none;"
                  :key="item['title'] + index"
                  :class="{'active': indexSelect === index}"
                  @click="toDetail(item, index)"
                  >
                  {{item['title']}}
                </v-list-item>
              </template>
            </v-list>


          </v-card>

          <div class="news-view-map mt-3" style="border-radius: 6px; border: 1px solid #eaeaea; padding: 15px; margin-bottom: 2px; background-color: #ffffff;">

            <div style="padding-bottom: 10px; font-size:13px;">
              <strong>GBP.COM.VN - VÒNG BI CHÍNH HÃNG</strong><br>
              Số 34, Ngõ 24, Đường Tả Thanh Oai, Xã Tả Thanh Oai, Huyện Thanh Trì, Hà Nội
              <br>
              Tel: 0942 332 228  <br>
              Email: vongbicongnghiep.gbp@gmail.com<br>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.8423917334817!2d105.79406705023939!3d20.998953994103015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad32c9f604db%3A0x3b906511bfb07c04!2zVsOybmcgYmkgY8O0bmcgbmdoaeG7h3A!5e0!3m2!1svi!2s!4v1594897946172!5m2!1svi!2s" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

          </div>
        </div>
        <div
          class="col-md-9 col-sm-9 col-xs-12"
        >
          <div v-html="modelBaiBiet['view']">

          </div>
          
        </div>
      </div>
    </v-container>
  </div>
</template>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }
</style>
<script>
    import news from '@/plugins/baiViet.json'
    export default {
        data: () => ({
            indexSelect: -1,
            modelBaiBiet: {
              view: ''
            },
            range: [0, 10000],
            select:'Popularity',
            options: [
                'Default',
                'Popularity',
                'Relevance',
                'Price: Low to High',
                'Price: High to Low',
            ],
            breadcrums: [
                {
                    text: 'Home',
                    disabled: false,
                    href: 'breadcrumbs_home',
                },
                {
                    text: 'Clothing',
                    disabled: false,
                    href: 'breadcrumbs_clothing',
                },
                {
                    text: 'T-Shirts',
                    disabled: true,
                    href: 'breadcrumbs_shirts',
                },
            ],
            min:0,
            max:10000,
            tinTucItems: news['items']
        }),
        created () {
          let vm = this
          vm.$nextTick(function () {
            let query = vm.$router.history.current.query
            console.log('query query', query)
            if (!vm.tinTucItems || !vm.tinTucItems['length']) {
              console.log('not tinTucItems', vm.tinTucItems)
              return
            }
            if (query && query['id']) {
              let itemBV = vm.tinTucItems.find((item, index) => {
                if (String(item['code']) === query['id']) {
                  vm.indexSelect = index
                  return true
                } else {
                  return false
                }
              })
              if (itemBV && itemBV['code']) {
                vm.modelBaiBiet = itemBV
              } else {
                vm.modelBaiBiet = vm.tinTucItems[0]
                vm.indexSelect = 0
              }
            } else {
              vm.modelBaiBiet = vm.tinTucItems[0]
              vm.indexSelect = 0
            }
            window.scrollTo(0, 0);
          })
        },
        watch: {
          '$route': function (newRoute, oldRoute) {
            let vm = this
            let query = newRoute.query
            console.log('query query', query)
            if (!vm.tinTucItems || !vm.tinTucItems['length']) {
              console.log('not tinTucItems', vm.tinTucItems)
              return
            }
            if (query && query['id']) {
              let itemBV = vm.tinTucItems.find((item, index) => {
                if (String(item['code']) === query['id']) {
                  vm.indexSelect = index
                  return true
                } else {
                  return false
                }
              })
              if (itemBV && itemBV['code']) {
                vm.modelBaiBiet = itemBV
              } else {
                vm.modelBaiBiet = vm.tinTucItems[0]
                vm.indexSelect = 0
              }
            } else {
              vm.modelBaiBiet = vm.tinTucItems[0]
              vm.indexSelect = 0
            }
            window.scrollTo(0, 0);
          }
        },
        methods: {
          toLink (link) {
            let vm = this
            if (link) {
              vm.$router.push({
                path: link
              })
            }
          },
          toDetail (item, index) {
            let vm = this
            vm.modelBaiBiet = item
            vm.indexSelect = index
            vm.$router.push({
              path: '/news',
              query: {
                id: item['code'] || 1
              }
            })
            console.log('vm.modelBaiBiet==========', vm.modelBaiBiet)
          }
        }
    }
</script>

<style>
  .news-view {
    padding: 20px 0 0;
  }
  .news-view .overview {
    line-height: 24px;
  }

  element.style {
    text-align: justify;
  }

  p {
      margin: 0 0 10px;
  }

  p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
  }

  .news-view .overview {
      line-height: 24px;
  }

  body {
      font-family: 'Roboto Condensed',sans-serif;
      font-size: 14px;
      font-weight: 300;
  }

  .news-view h1 {
    line-height: 30px;
    margin: 0;
    padding: 0 0 10px;
    font-size: 20px;
    color: #333;
    font-weight: 700;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  .news-view .overview table td {
    border: 1px solid #eee;
    padding: 5px;
  }

  .news-view .overview img {
    max-width: 100%!important;
    height: auto!important;
  }
  .v-list-item.v-list-item--link.theme--light:hover {
    background: #dbdbdb;
  }
  .v-list-item.v-list-item--link.theme--light.active {
    background: #dbdbdb;
  }
  .news-view-map {
    display: block;
  }
  @media only screen and (max-width: 600px) {
    div.v-toolbar__title {
      width: 90px !important;
    }
    .news-view-map {
      display: none;
    }
    .v-toolbar__content {
      padding-right: 6px !important;
    }
  }
</style>
