<template>
  <div>
    <v-container>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 py-0">
          <v-breadcrumbs class="pb-0" :items="breadcrums">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="toLink(item.href)"
                :disabled="item.disabled"
                >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <div class="category-info mt-4" style="border-radius: 8px; border: 1px solid #ccc; padding-top: 0px; margin-bottom: 0px;">
            <h1 style="font-size: 18px; margin-top: 20px; margin-bottom: 10px; ;text-transform: uppercase;font-weight:700; line-height:24px">Catalogue sản phẩm SKF chính hãng</h1>
            <h2 style="font-size: 14px; margin-top: 20px; margin-bottom: 10px; font-weight: 300; line-height: 22px">Download datasheet, Catalogue vòng bi, Catalogue các sản phẩm chính hãng, Tài liệu kỹ thuật, hướng dẫn sử dụng của các sản phẩm chính hãng</h2>
          </div>

          <div class="list-category" style="margin: 30px 0 0">
            <div class="row">

              <div class="col-sm-4 col-xs-6 cat" style="text-transform: uppercase;">
                <a target="_blank" href="https://vongbingocanh.com/download/O_bi_do_motday.pdf" title="Catalogue Ổ bi đỡ 1 dãy">
                  <span class="img"><img style="width: 56px; height: 56px;" src="https://vongbi.com/public/uploads/images/121/photjpg-150x150c-150x150c.jpg?v=2.3.8"></span>
                </a>
                <div>
                  <a target="_blank" href="https://vongbingocanh.com/download/O_bi_do_motday.pdf" title="Catalogue Ổ bi đỡ 1 dãy">
                    <span class="txt">Catalogue Ổ bi đỡ 1 dãy</span>
                  </a>
                  <span style="text-transform: none; color: #000; font-weight: 300;"></span>
                </div>
              </div>

              <div class="col-sm-4 col-xs-6 cat" style="text-transform: uppercase;">
                <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2020/11/didcatalog-1.pdf" title="CATALOGUE DID CHAIN (CATALOGUE XÍCH DID)">
                  <span class="img"><img style="width: 56px; height: 56px;" src="http://chm.com.vn/wp-content/uploads/2020/10/z2120276962634_afcfbb3b1a7cada521e2b20db96bb68a-195x145.jpg"></span>
                </a>
                <div>
                  <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2020/11/didcatalog-1.pdf" title="CATALOGUE DID CHAIN (CATALOGUE XÍCH DID)">
                    <span class="txt">CATALOGUE DID CHAIN (CATALOGUE XÍCH DID)</span>
                  </a>
                  <span style="text-transform: none; color: #000; font-weight: 300;"></span>
                </div>
              </div>
              <div class="col-sm-4 col-xs-6 cat" style="text-transform: uppercase;">
                <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2020/11/Full-Range-IKO-cat5508-1.pdf" title="CATALOGUE IKO BEARING ( CATALOGUE VÒNG BI IKO )">
                  <span class="img"><img style="width: 56px; height: 56px;" src="http://chm.com.vn/wp-content/uploads/2016/03/IMG_2041-195x145.jpg"></span>
                </a>
                <div>
                  <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2020/11/Full-Range-IKO-cat5508-1.pdf" title="CATALOGUE IKO BEARING (CATALOGUE VÒNG BI IKO)">
                    <span class="txt">CATALOGUE IKO BEARING (CATALOGUE VÒNG BI IKO)</span>
                  </a>
                  <span style="text-transform: none; color: #000; font-weight: 300;"></span>
                </div>
              </div>

              <div class="col-sm-4 col-xs-6 cat" style="text-transform: uppercase;">
                <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2019/12/NSK-Precision-Bearings-1.pdf" title="CATALOGUE VÒNG BI CHÍNH XÁC NSK">
                  <span class="img"><img style="width: 56px; height: 56px;" src="http://chm.com.vn/wp-content/uploads/2016/03/NSK-2_3-195x145.jpg"></span>
                </a>
                <div>
                  <a target="_blank" href="http://chm.com.vn/wp-content/uploads/2019/12/NSK-Precision-Bearings-1.pdf" title="CATALOGUE VÒNG BI CHÍNH XÁC NSK">
                    <span class="txt">CATALOGUE VÒNG BI CHÍNH XÁC NSK</span>
                  </a>
                  <span style="text-transform: none; color: #000; font-weight: 300;"></span>
                </div>
              </div>

              <div class="col-sm-4 col-xs-6 cat" style="text-transform: uppercase;">
                <a target="_blank" href="https://vongbi.com/download/san-pham-chuyen-dong-skf.pdf" title="Catalogue SP truyền động SKF">
                  <span class="img"><img style="width: 56px; height: 56px;" src="https://vongbi.com/public/uploads/images/123/skf-chainsjpg-150x150c-150x150c.jpg?v=2.3.8"></span>
                </a>
                <div>
                  <a target="_blank" href="https://vongbi.com/download/san-pham-chuyen-dong-skf.pdf" title="Catalogue SP truyền động SKF">
                    <span class="txt">Catalogue SP truyền động SKF</span>
                  </a>
                  <span style="text-transform: none; color: #000; font-weight: 300;"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </v-container>
    <v-card  class="hotline" >
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-12 col-sm-12 text-center">
            <div class="container">
              <span class="dot" style="margin-left: 5px; margin-top: -4px;">
                <span class="ping"></span>
              </span>
              <span style="font-weight:300; color: #fff;">Hotline hỗ trợ:</span>
              <!-- <i class="fas fa-phone-square" style="color:#38c500;padding-left: 18px"></i> -->
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">024 668 42879</span>
              </a>
              <a  href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">0916 045 428</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
    export default {
        data: () => ({
            pageCount: 0,
            stateDetail: false,
            rating:4.5,
            page: 1,
            modelProduct: {},
            breadcrums: [
                {
                    text: 'Trang chủ',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Tài liệu kỹ thuật',
                    disabled: false,
                    href: '/document',
                }
            ]
        }),
        created () {
          let vm = this
          vm.$nextTick(function () {
            let query = vm.$router.history.current.query
            window.scrollTo(0, 0);
          })
        },
        watch: {
          'page' (val) {
          },
          '$route': function (newRoute, oldRoute) {
          }
        },
        methods: {
          toLink (link) {
            let vm = this
            if (link) {
              vm.$router.push({
                path: link
              })
            }
          }
        }
    }
</script>

<style>
  .name-product-detail {
    color: #044d7f;
    font-size: 24px;
    font-weight: normal;
  }
  .theme--light.v-data-table thead tr:last-child th {
    border-bottom: thin solid rgba(0,0,0,.12);
    background: #d9d9d9;
  }
  table#pro td {
    padding: 8px 3px !important;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  table#pro {
    border-collapse: collapse;
    width: 100%;
    color: #484646;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  table#pro th {
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .name-bearings-detail {
    background: #ccc;
    text-align: center;
    font-size: 20px;
    color: #167fc1;
  }
  .description-content {
    text-align: justify;
    padding: 8px;
    border-bottom: 1px solid#ddd;
    border-top: 1px solid#ddd;
  }

  .category-info {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 3px;
  }

  .list-category .cat a:hover {
    color: #003586;
    text-decoration: none;
  }

  .list-category .cat .txt {
    display: block;
    padding: 5px 0 0;
    line-height: 24px;
    font-size: 13px;
    font-weight: 400;
  }

  .list-category .cat a {
    color: #333;
  }

  .list-category .cat .img {
    border-radius: 6px;
    display: block;
    border: 1px solid #cfcfcf;
    padding: 5px;
    float: left;
    margin: -6px 15px 0 0;
    width: 68px;
  }

  .list-category .cat .img img {
    max-width: 100%;
  }
  img {
    vertical-align: middle;
  }

  img {
    border: 0;
  }
</style>

<style>
@media (min-width: 1024px) {
  main .list-category {
    margin-top: 30px;
  }
}

main .list-category .cat:after {
  content: '';
  display: block;
  clear: both;
}

main .list-category .clearfix3 {
  clear: both;
  display: block;
}

@media (max-width: 767px)and (min-width: 630px) {
  main .list-category .clearfix2 {
    clear: both;
    display: block;
  }

  main .list-category .clearfix3 {
    display: none;
    clear: none;
  }

  main .list-category .cat .txt {
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  main .list-category .cat {
    margin-bottom: 28px;
  }

  main .list-category .cat.col-xs-6 {
    width: 100%;
    float: none;
  }

  main .list-category .cat .txt {
    font-size: 13px;
  }
  div.v-toolbar__title {
    width: 90px !important;
  }
  .v-toolbar__content {
    padding-right: 6px !important;
  }
}


</style>
