<template>
  <div>
    <v-carousel hide-delimiters
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      interval="3000"
    >
      <v-carousel-item
        :src="require('../assets/img/home/slider_other_1.jpg')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <!-- <div class="display-2 white--text pl-5 pr-5 hidden-sm-only"><strong>Upto 60% + Extra 10%</strong></div><br/> -->
        </v-row>
      </v-carousel-item>
      <v-carousel-item
        :src="require('../assets/img/home/slider_other_3.jpg')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
        </v-row>
      </v-carousel-item>
      <v-carousel-item
        :src="require('../assets/img/home/slider_other_4.jpg')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
        </v-row>
      </v-carousel-item>

      <v-carousel-item
        :src="require('../assets/img/home/slider_other_2.jpg')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height fill-width"
          align="center"
          justify="center"
        >
        </v-row>
      </v-carousel-item>

      <v-carousel-item
        :src="require('../assets/img/home/slider_other_5.png')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
        </v-row>
      </v-carousel-item>

      <v-carousel-item
        :src="require('../assets/img/home/slider_other_6.png')"
        aspect-ratio="1.7"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
        </v-row>
      </v-carousel-item>

    </v-carousel>

    <v-container class="hang-san-xuat">
      <div class="row">
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/FAG.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/SKF.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/NSK.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/NTN.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
            </div>

      <div class="row">
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/KOYO.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/THK.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/IKO.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center pb-0">
                <v-hover
                  open-delay="200"
                >
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    width="300px"
                    :src="require('../assets/img/home/TIMKEN.jpg')"
                  >
                  </v-img>
                </v-hover>
              </div>
            </div>
    </v-container>
    <v-container>
      <v-row no-gutters>
        <v-col :cols="12">
          <v-card-text
            class=""
            tile
            outlined
          >
            <v-card-title class="subheading " style="background: #044E7F; color: #fff; border-radius: 5px;">
              Danh mục sản phẩm
            </v-card-title>
            <v-divider></v-divider>
            <div class="row pt-4">
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center">
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDTC&page=1"
                    style="cursor: pointer;"
                    :elevation="hover ? 16 : 2"
                >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    contain
                    :src="require('../assets/img/vongbi/bac_dan_tang_chong.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN TANG TRỐNG</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCN&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    contain
                    :src="require('../assets/img/vongbi/bac_dan_con.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN CÔN</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDK&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_kim.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN KIM</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDD&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_dua.png')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN ĐŨA</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center">
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCTL&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_cau_tu_lua.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI -BẠC ĐẠN CẦU TỰ LỰA</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>

              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDTX4D&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_tiep_xuc_4_diem.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">BẠC ĐẠN TIẾP XÚC 4 ĐIỂM</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>

              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCDC&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_cau_do_chan.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI -BẠC ĐẠN CẦU ĐỠ CHẶN</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCU&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_cau.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN CẦU</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center">
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCT&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_chan_truc.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN CHẶN TRỤC</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDCTTC&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_truc_tang_chong.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN CHẶN TRỤC TANG TRỐNG</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>

              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/shop?idType=BDTXG2D&page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/bac_dan_tiep_xuc_goc_2_day.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">VÒNG BI - BẠC ĐẠN TIẾP XÚC GÓC HAI DẪY</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>

              <div class="col-6 col-md-3 col-sm-6 col-xs-6 text-center" >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    to="/accessories?page=1"
                    :elevation="hover ? 16 : 2"
                    style="cursor: pointer;"
                  >
                  <v-img
                    class="white--text align-end img-type-home"
                    height="200px"
                    :src="require('../assets/img/vongbi/phu_kien_vong_bi.jpg')"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <h3 style="font-weight: normal;">PHỤ KIỆN VÒNG BI</h3>
                  </v-card-text>

                  <div class="text-center">
                  </div>
                </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <div class="introduce" style="background: #FFF;">
      <v-container class="pt-0">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 pt-0">

            <v-card-title class="subheading " style="background: #044E7F; color: #fff; border-radius: 5px;">
              GIỚI THIỆU CHUNG VỀ GBP
            </v-card-title>
            <p style="text-align:justify"><img alt="" src="../assets/img/home/introduce.png" style="height:541px; width:100%;"></p>
            <p>
              <span style="font-weight: bold;">Công ty cổ phần thiết bị công nghiệp GBP Việt Nam</span> là một trong những công ty nhập khẩu, phân phối vòng bi và gối đỡ công nghiệp hàng đầu Việt Nam. <span style="font-weight: bold;">GBP</span> phân phối rộng khắp thị trường Việt Nam những sản phẩm được nhập khẩu trực tiếp từ <span style="font-weight: bold;">Mỹ, Nhật Bản, Singapore</span> …
            </p>
            <p>
              Với phương châm <span style="font-weight: bold;">“hàng chính hãng – chất lượng hàng đầu – giá cả cạnh tranh”</span>, <span style="font-weight: bold;">GBP</span> đang không ngừng nỗ lực hoàn thiện và phát triển bản thân. Với cương vị là nhà cung cấp tin cậy cho các tập đoàn công nghiệp lớn trong các lĩnh vực như: <span style="font-weight: bold;">thuỷ điện, khai khoáng, mía đường, sản xuất giấy, xi măng</span> … thậm chí là các nhà bán lẻ và đơn vị OEM, O&M, chúng tôi cam kết sẽ mang đến cho bạn những sản phẩm tuyệt vời nhất.
            </p>

            <p>
              Hiện nay <span style="font-weight: bold;">GBP</span> chủ yếu phân phối vòng bi của các hãng: <span style="font-weight: bold;">SKF, NSK, INA, FAG</span>. Ngoài ra công ty cũng nhập khẩu và phân phối các nhãn hiệu vòng bi khác như: <span style="font-weight: bold;">NTN, KOYO, TIMKEN, IKO, ASHAHI, MPZ</span>… gối đỡ <span style="font-weight: bold;">GLH</span>, măng sông GP. <span style="font-weight: bold;">GBP</span> chỉ cung cấp các vòng bi chính hãng từ các thương hiệu uy tín trên thế giới. Có sẵn hơn <span style="font-weight: bold;">12.000</span> mã vòng bi, phụ kiện trong kho. <br><span style="font-weight: bold;">GBP</span> luôn sẵn sàng đáp ứng mọi nhu cầu của khách hàng trong thời gian ngắn nhất. Giao hàng với thời gian ngắn nhất, không kể khoảng cách xa hay gần với giá cả cạnh tranh, đặc biệt dịch vụ chăm sóc, bảo hành sau bán hàng chuyên nghiệp. <span style="font-weight: bold;">GBP</span> ngoài cung cấp các sản phẩm vòng bi và các phụ kiện vòng bi còn cung cấp các vật tư công nghiệp. Chúng tôi luôn nỗ lực không ngừng mang lại cho khách hàng những sản phẩm vật tư tốt nhất, những mặt hàng phù hợp với mọi yêu cầu của khách hàng.
            </p>

            <p>
              - Với phương châm làm việc <span style="font-weight: bold;">“KHÁCH HÀNG LÀ GIÁ TRỊ CỐT LÕI” </span> mỗi cán bộ, nhân viên của <span style="font-weight: bold;">GBP VN.,JSC </span> luôn không ngừng học hỏi nâng cao kinh nghiệm, nâng cao kỹ năng tự hoàn thiện bản thân để có thể phục vụ, chăm sóc khách hàng tốt hơn trong lĩnh vực <span style="font-weight: bold;">Vòng Bi (Bạc đạn), Gối đỡ </span> <br> <br>

              - Rất mong được phục vụ quý <span style="font-weight: bold;">Khách Hàng</span> và <span style="font-weight: bold;">Quý Công Ty </span>- <span style="font-weight: bold;">Doanh Nghiệp</span> nhiều hơn trong lĩnh vực <span style="font-weight: bold;">Vòng Bi (Bạc đạn), Gối đỡ công nghiệp</span>.
            </p>
          </div>
        </div>
      </v-container>
    </div>

    <div style="padding: 30px 0; background-color: #003586;" class="row whyBox">
      <div class="col-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <h1 class="box-heading">Mua bán Vòng bi bạc đạn chính hãng uy tín số 1 Việt Nam</h1>
        <p class="box-website" style="font-weight: 300; font-size:22px; color:#fff; margin-bottom: 0;">"gbp.com.vn - Tìm đúng nơi, mua đúng chỗ"</p>
      </div>
      <v-container>
        <div class="row">
          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-1.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>Online - Offline</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Sản phẩm được kinh doanh linh hoạt, Online và Offline. Bạn có thể mua hàng trực tiếp theo cách truyền thống hoặc Online, Giúp bạn tiết kiệm thời gian và tiền bạc.
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-2.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>SẢN PHẨM CHÍNH HÃNG</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Phân phối sản phẩm chính hãng, Chúng tôi cam kết hoàn tiền đơn hàng nếu phát hiện hàng giả, hàng nhái từ gbp.com.vn
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-3.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>Online - Offline</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Mọi sản phẩm bán ra đều có thông tin rõ ràng, minh bạch cũng như các chế độ sau bán hàng.
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-4.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>BẢO HÀNH CHÍNH HÃNG</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Các sản phẩm do chúng tôi bán ra đều được bảo hành chính hãng, Cơ chế bảo hành đổi mới linh hoạt. Hỗ trợ tối đa lợi ích của Khách hàng.
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-5.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>HỖ TRỢ KỸ THUẬT 24/7</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Đội ngũ bán hàng & kỹ thuật của chúng tôi luôn sẵn sàng phục vụ và hỗ trợ bạn mọi lúc mọi nơi tất cả các ngày trong tuần 24/24.
                </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12 _item">
            <div class="icon _icon1">
              <v-img
              contain
              class="white--text align-end"
              :src="require('../assets/img/home/why-icon-6.png')"
              >
            </v-img>
            </div>
            <div class="info">
              <h2 class="title" itemprop="name"><i class="fas fa-caret-square-right" style="color: #38c500; padding-right: 5px;
              "></i>GIAO HÀNG TOÀN QUỐC</h2>
              <div class="desc" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <span itemprop="text">
                  Cho dù bạn ở đâu? chúng tôi luôn sẵn sàng được phục vụ nhu cầu của bạn với chế độ giao hàng và thanh toán linh hoạt trên toàn quốc.
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <div class="newsBox" style="background: #F4F9FE;">
      <v-container>
        <div class="row">
          <div class="col-12 col-md-7 col-sm-12 col-xs-12">
            <h4 class="box-heading news text-uppercase">
              <a href="javascript:;">Bài viết - Tin tức</a>
            </h4>
            <ul class="list-item list-unstyled" style="padding-left: 0;">
              <li class="_item ">
                <a @click="toLink('/news?id=2')">
                  <img class="lazyload" src="https://vongbi.com/public/uploads/images/861/screen-shot-2020-04-02-at-032626-250x139c.png" style="display: block;">
                  <div class="info">
                    <h2 class="news-title">NHỮNG DẤU HIỆU NHẬN BIẾT HƯ HỎNG VÒNG BI, NGUYÊN NHÂN VÀ CÁCH KHẮC PHỤC</h2>
                    <p>Trong bảo trì công nghiệp hiện nay, việc sử dụng một số thiết bị lắp đặt, căn chỉnh chẩn đoán lỗi thiết bị đã tạo ra một bước đột phá trong công việc bảo trì theo mô hình Bảo trì theo điều kiện...</p>
                  </div>
                </a>
              </li>
              <li class="_item ">
                <a @click="toLink('/news?id=1')">
                  <img class="lazyload" alt="Mua vòng bi SKF chính hãng ở đâu uy tín giá rẻ" title="Mua vòng bi SKF chính hãng ở đâu uy tín giá rẻ" src="https://vongbi.com/public/uploads/images/184/48429503-2231553093754454-5222208455548338176-o-500x330c-250x139c.jpg" style="display: block;">
                  <div class="info">
                    <h2 class="news-title">THẾ NÀO LÀ TẢI TRỌNG TỐI THIỂU VÀ TẦM QUAN TRỌNG ĐỐI VỚI VÒNG BI?</h2>
                    <p>Trước tiên ta cần một cái nhìn tổng quan. Vòng bi ổ lăn vốn được sử dụng để làm giảm ma sát trong máy móc, loại bỏ tối đa ma sát trượt khỏi hệ thống ...</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-1 col-sm-12 col-xs-12">
          </div>

          <div class="col-12 col-md-4 col-sm-12 col-xs-12">
            <h4 class="box-heading video text-uppercase">
              <a href="javascript:;" style="pointer-events: none;" target="_blank" rel="nofollow">Video clip</a>
            </h4>
            <div class="box-content video">
              <div class="frame">
                <iframe width="100%" height="190" src="https://www.youtube.com/embed/dNiwCQ3-m5I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <a rel="nofollow" href="https://www.youtube.com/watch?v=EaQM7THtwUU" target="_blank" class="title">
                Cách thay bạc đạn vòng bi nhanh nhất và hiệu quả nhất
              </a>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <v-card  class="hotline" >
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-12 col-sm-12 text-center">
            <div class="containerHome">
              <span class="dot" style="margin-left: 5px; margin-top: -4px;">
                <span class="ping"></span>
              </span>
              <span style="font-weight:300; color: #fff; position: relative; top: 2px;">Hotline hỗ trợ:</span>
              <a href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">024 668 42879</span>
              </a>
              <a href="tel:0942 332 228" title="hotline hỗ trợ" rel="nofollow">
                <v-icon style="font-size: 17px; padding-left: 18px;">mdi-phone-classic</v-icon>
                <span style="color: #fff; font-family: 'Bebas Neue'; font-size: 18px; position: relative; top: 4px; left: 4px;">0916 045 428</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    export default {
        data () {
            return {
                items: [
                    { title: 'Click Me' },
                    { title: 'Click Me' },
                    { title: 'Click Me' },
                    { title: 'Click Me 2' },
                ],
                activeBtn: 1,
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                ],
            }
        },
        methods: {
          toLink (link) {
            let vm = this
            if (link) {
              vm.$router.push({
                path: link
              })
            }
          }
        }
    }
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  .whyBox .box-heading {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    font-weight: 700;
    font-size: 24px;
  }
  .whyBox ._item {
    padding-top: 40px;
    padding-botom: 50px;
  }
  .whyBox ._item .info {
    overflow: hidden;
    padding-top: 10px;
    background: #003586 !important;
  }
  .whyBox ._item .info .title {
    font-size: 14px !important;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 0px;
    font-weight: 700;
  }

  .whyBox ._item .icon {
    margin-top: 20px;
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .whyBox ._item .info .desc {
    font-size: 11px;
    color: #fff;
    margin: 0;
    padding: 0 0 10px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    font-weight: 300;
  }
  .newsBox .box-heading {
    font-size: 20px;
    color: #003586;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  .newsBox .box-heading.video:before {
    background-image: url(../assets/img/home/video.png);
  }

  .newsBox .box-heading.news:before {
    background-image: url(../assets/img/home/news.png);
  }
  .newsBox .box-heading:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px;
    margin-right: 10px;
    background-size: contain;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  .newsBox .list-item ._item {
    padding: 15px 0;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
  .newsBox .list-item ._item img {
    background: #fff;
    padding: 5px;
    border: 1px solid #eee;
    width: 25%;
    float: left;
    margin-right: 20px;
    margin-top: 5px;
  }
  .newsBox .list-item ._item a {
    color: #000;
  }
  .newsBox .list-item ._item .info {
    overflow: hidden;
    background: #F4F9FE !important;
  }
  .newsBox .list-item ._item .info .news-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 5px 0 0;
    line-height: 24px;
  }
  .newsBox .list-item ._item .info p {
    font-size: 13px;
    text-align: justify;
  }
  .containerHome p {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 5px 0 12px;
  }
  .newsBox .list-item ._item:after {
    content: "";
    display: block;
    clear: both;
  }
  .newsBox .box-content.video {
    padding: 15px 0 0;
  }
  .newsBox .box-heading {
    font-size: 20px;
    color: #003586;
  }
  .newsBox .box-content.video .frame {
    padding: 5px 5px 2px;
    background: #fff;
    border: 1px solid #eee;
  }
  .newsBox .box-content.video a.title {
    font-size: 16px !important;
    font-weight: 700;
    display: block;
    padding: 0;
    color: #000;
  }
  .img-full {
    width: 100%;
    height: auto;
  }

  .nav__icon {
    display: none;
  }
  .space-nav {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    /*div.hang-san-xuat {
      display: none;
    }*/

    .img-product {
      height: 120px !important;
    }

    .whyBox {
      padding: 15px 0px !important;
    }

    .whyBox ._item {
      padding-top: 0;
      padding-left: 22px;
      padding-right: 20px;
    }

    .whyBox .box-heading {
      font-size: 20px;
    }
    .whyBox .box-website {
      font-size: 19px !important;
    }

    div.whyBox > .col-12.col-md-12.col-sm-12.col-xs-12.text-center {
      padding-left: 10px;
      padding-right: 10px;
    }

    div.hang-san-xuat {
      height: 250px;
      overflow: auto;
      padding-top: 0;
    }

    div.hang-san-xuat .v-responsive.v-image.white--text.align-end {
      height: 70px !important;
    }
    div.v-toolbar__title {
      width: 90px !important;
    }
    .v-responsive.v-image.v-carousel__item {
      height: 150px !important;
    }
    .v-window.v-item-group.theme--dark.v-window--show-arrows-on-hover.v-carousel.v-carousel--hide-delimiter-background {
      height: 150px !important;
    }
    .img-type-home {
      height: 120px !important;
    }
    .__nav {
      display: none !important;
    }
    .img-footer-logo {
      height: 80px !important;
    }
    .space-nav {
      height: 116%;
      width: 1px;
      background: #ccc;
      padding-left: 1px;
      margin-left: 5px;
      margin-right: 12px;
    }
    .nav__icon {
      display: block;
    }
    .space-nav {
      display: block;
    }
  }
</style>

