import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Shop from '@/components/Shop'
import Product from '@/components/Product'
import Blog from '@/components/Blog'
import News from '@/components/News'
import Post from '@/components/Post'
import Cart from '@/components/Cart'
import Introduce from '@/components/Introduce'
import Accessories from '@/components/Accessories'
import Document from '@/components/Document'
import Layout from '@/components/Layout'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: Layout,
      children:[
        {
          path:'/',
          component:Home,
          name:'Home'
        },
        {
          path:'introduce',
          component:Introduce,
          name:'Introduce'
        },
        {
          path:'/shop',
          component:Shop,
          name:'Shop'
        },
        {
          path:'/product',
          component:Product,
          name:'Product'
        },
        {
          path:'/blog',
          component:Blog,
          name:'Blog'
        },
        {
          path:'/news',
          component:News,
          name:'News'
        },
        {
          path:'/post',
          component:Post,
          name:'Post'
        },
        {
          path:'/cart',
          component:Cart,
          name:'Cart'
        },
        {
          path:'/accessories',
          component:Accessories,
          name:'Accessories'
        },
        {
          path:'/document',
          component:Document,
          name:'Document'
        }
      ]

    }
  ],
    mode:'history'
},

  )
