<template>
  <v-app>
      <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
a {
	text-decoration: none;
}
@font-face {
  font-family: "Bebas Neue";
  src: local("Bebas Neue"),
   url(assets/fonts/bebas_neue/BebasNeue-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condensed"),
   url(assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format("truetype");
}
</style>
